import React, { useState, useEffect } from 'react';
import { X, CreditCard, Bitcoin, Wallet, ArrowRight, Gift } from 'lucide-react';
import { useWallet } from '@/hooks/useWallet';
import { StripePaymentForm } from '../StripePaymentForm';
import { paymentService } from '@/services/api/payments';
import { voucherService } from '@/services/api/vouchers';
import { supportedChains } from '@/config/web3';
import toast from 'react-hot-toast';

interface Property {
  id: string;
  title: string;
  minInvestment: number;
  targetAmount: number;
  raisedAmount: number;
  maxInvestment: number;
}

interface InvestmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  property: Property;
  onInvest: (amount: number, method: 'stripe' | 'crypto' | 'voucher') => void;
}

type PaymentMethod = 'stripe' | 'crypto' | 'voucher';

export function InvestmentModal({ isOpen, onClose, property, onInvest }: InvestmentModalProps) {
  const [amount, setAmount] = useState<number>(property?.minInvestment || 0);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('stripe');
  const [selectedChain, setSelectedChain] = useState(supportedChains.ethereum.id);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<{ clientSecret: string; id: string } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const { isConnected, connect, isLoading: isWalletLoading, chain, switchNetwork } = useWallet();

  // Check for saved voucher code on mount
  useEffect(() => {
    const savedCode = localStorage.getItem('voucherCode');
    if (savedCode) {
      setVoucherCode(savedCode);
      setPaymentMethod('voucher');
    }
  }, []);

  const remainingAmount = property.targetAmount - property.raisedAmount;

  const validateAmount = (value: number) => {
    if (value < property.minInvestment) {
      toast.error(`Minimum investment amount is $${property.minInvestment.toLocaleString()}`);
      return false;
    }
    
    if (value > property.maxInvestment) {
      toast.error(`Maximum investment amount is $${property.maxInvestment.toLocaleString()}`);
      return false;
    }

    if (value > remainingAmount) {
      toast.error(`Maximum available investment amount is $${remainingAmount.toLocaleString()}`);
      return false;
    }

    return true;
  };

  const handleVoucherValidation = async () => {
    if (!voucherCode) {
      toast.error('Please enter a voucher code');
      return;
    }

    try {
      const response = await voucherService.getVoucherDetails(voucherCode);
      if (response.success && response.data) {
        const voucher = response.data;
        
        // Validate voucher status
        if (voucher.status === 'redeemed') {
          toast.error('This voucher has already been redeemed');
          return;
        }
        if (voucher.status === 'expired') {
          toast.error('This voucher has expired');
          return;
        }
        if (voucher.status !== 'sent') {
          toast.error('Invalid voucher status');
          return;
        }

        // Validate amount against property limits
        const voucherAmount = Number(voucher.amount);
        if (!validateAmount(voucherAmount)) {
          return;
        }

        // Store voucher code and proceed with investment
        localStorage.setItem('voucherCode', voucherCode);
        setAmount(voucherAmount);
        onInvest(voucherAmount, 'voucher');
        onClose();
      } else {
        throw new Error(response.message || 'Invalid voucher code');
      }
    } catch (error) {
      console.error('Voucher validation error:', error);
      toast.error(error instanceof Error ? error.message : 'Invalid voucher code');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (paymentMethod === 'voucher') {
      await handleVoucherValidation();
      return;
    }

    if (!validateAmount(amount)) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (paymentMethod === 'stripe') {
        const response = await paymentService.createPaymentIntent({
          amount,
          propertyId: property.id,
          method: 'stripe',
          isRecurring
        });

        if (!response.success || !response.data) {
          throw new Error(response.message || 'Failed to create payment intent');
        }

        setPaymentIntent(response.data);
        setShowPaymentForm(true);
      } else if (paymentMethod === 'crypto') {
        if (!isConnected) {
          await connect();
          return;
        }

        if (chain?.id !== selectedChain && switchNetwork) {
          await switchNetwork(selectedChain);
          return;
        }

        const response = await paymentService.processCryptoPayment(
          amount,
          selectedChain,
          property.id
        );

        if (!response.success) {
          throw new Error('Failed to process crypto payment');
        }

        onInvest(amount, 'crypto');
        onClose();
      }
    } catch (error) {
      console.error('Investment error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to process payment');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePaymentSuccess = () => {
    onInvest(amount, 'stripe');
    onClose();
    toast.success('Investment successful!');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 animate-fade-in">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">Invest in {property.title}</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>

          {!showPaymentForm ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Investment Amount
                </label>
                <div className="relative">
                  <span className="absolute left-4 top-3 text-gray-500">$</span>
                  <input
                    type="number"
                    min={property.minInvestment}
                    max={Math.min(property.maxInvestment, remainingAmount)}
                    step={100}
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full pl-8 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                    disabled={paymentMethod === 'voucher'}
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  Min: ${property.minInvestment.toLocaleString()} | Max: ${Math.min(property.maxInvestment, remainingAmount).toLocaleString()}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Payment Method
                </label>
                <div className="grid grid-cols-3 gap-4">
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('stripe')}
                    className={`flex flex-col items-center justify-center gap-2 p-4 rounded-lg border ${
                      paymentMethod === 'stripe'
                        ? 'border-primary-500 bg-primary-50 text-primary-700'
                        : 'border-gray-200 hover:border-primary-500'
                    }`}
                  >
                    <CreditCard className="w-5 h-5" />
                    <span>Card</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('crypto')}
                    className={`flex flex-col items-center justify-center gap-2 p-4 rounded-lg border ${
                      paymentMethod === 'crypto'
                        ? 'border-primary-500 bg-primary-50 text-primary-700'
                        : 'border-gray-200 hover:border-primary-500'
                    }`}
                  >
                    <Bitcoin className="w-5 h-5" />
                    <span>Crypto</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => setPaymentMethod('voucher')}
                    className={`flex flex-col items-center justify-center gap-2 p-4 rounded-lg border ${
                      paymentMethod === 'voucher'
                        ? 'border-primary-500 bg-primary-50 text-primary-700'
                        : 'border-gray-200 hover:border-primary-500'
                    }`}
                  >
                    <Gift className="w-5 h-5" />
                    <span>Gift Card</span>
                  </button>
                </div>
              </div>

              {paymentMethod === 'crypto' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select Network
                  </label>
                  <select
                    value={selectedChain}
                    onChange={(e) => setSelectedChain(Number(e.target.value))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  >
                    {Object.values(supportedChains).map((chain) => (
                      <option key={chain.id} value={chain.id}>
                        {chain.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {paymentMethod === 'voucher' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Gift Card Code
                  </label>
                  <input
                    type="text"
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value.toUpperCase())}
                    placeholder="Enter gift card code"
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  />
                </div>
              )}

              {paymentMethod === 'stripe' && (
                <div className="mb-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={isRecurring}
                      onChange={(e) => setIsRecurring(e.target.checked)}
                      className="rounded text-primary-600 focus:ring-primary-500"
                    />
                    <span className="text-sm text-gray-700">Set up recurring monthly investment</span>
                  </label>
                </div>
              )}

              <button
                type="submit"
                disabled={isSubmitting || isWalletLoading || (!amount && paymentMethod !== 'voucher') || (paymentMethod === 'voucher' && !voucherCode)}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
              >
                {isSubmitting || isWalletLoading ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    {isWalletLoading ? 'Connecting Wallet...' : 'Processing...'}
                  </>
                ) : (
                  <>
                    {paymentMethod === 'crypto' && !isConnected ? (
                      <>
                        <Wallet className="w-5 h-5" />
                        Connect Wallet
                      </>
                    ) : (
                      <>
                        Continue
                        <ArrowRight className="w-5 h-5" />
                      </>
                    )}
                  </>
                )}
              </button>
            </form>
          ) : (
            paymentIntent && (
              <StripePaymentForm
                amount={amount}
                onSuccess={handlePaymentSuccess}
                onCancel={() => setShowPaymentForm(false)}
                clientSecret={paymentIntent.clientSecret}
                isRecurring={isRecurring}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}